.navbar-default {
    border-color: transparent;
    background: transparent url(../imgs/SecondaryTexture.png);
}
.navbar-default .navbar-brand {
    color: #ffffff;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: #ffffff;
}
.navbar-default .navbar-text {
    color: #ffffff;
}
.navbar-default .navbar-nav > li > a {
    color: #ffffff;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #ffffff;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #ffffff;
    background-color: transparent;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    color: #ffffff;
    background-color: transparent;
}
.navbar-default .navbar-toggle {
    border-color: transparent;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #ffffff;
}
.navbar-default .navbar-link {
    color: #ffffff;
}
.navbar-default .navbar-link:hover {
    color: #ffffff;
}

.dropdown-menu>li>a{
    color: #a7670d;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #ffffff;

    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ffffff;
        background-color: transparent;
    }
}
