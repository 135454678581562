/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
  background: url(../imgs/BGTexture.png);
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  background: url(../imgs/BGTexture.png);
}

header {
  background: url(../imgs/PrimaryTexture-Alt.png);
}

p, ul.std-size li {
  font-size: 18px;
}

p.medium {
  font-size: 16px;
}

p.small {
  font-size: 14px;
}

.text-bold {
  font-weight: bold;
}

.text-big {
  font-size: 18px;
}

.well, .img-thumbnail {
  background-color: #DED3C7;
}

a:hover {
  cursor: pointer;
}

a.box-menu:hover {
  text-decoration: none;
  color: inherit;
}

section.low {
  margin-top: 10px;
}

.nav-top-site {
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  border-radius: 0 !important;
}

.footer {
  position: absolute;
  width: 100%;
  background: url(../imgs/SecondaryTexture.png);
  border-top: solid 10px #1D2D18;
  color: #fff;
}

.footer .low {
  height: 80px;
  background-color: #333333;
  color: #FFFFFF;
}

.footer ul {
  list-style: none;
}

.footer ul li {
  border-bottom: solid 1px #ccc;
  line-height: 2em;
}

.footer ul li a {
  color: #fff;
}

p.footer-low {
  padding-top: 30px;
  padding-left: 30px;
  font-size: 12px;
}

a.mail-to, a.mail-to:hover {
  color: #FF9A98;
  font-size: 16px;
}

.navbar {
  margin-bottom: 0;
}

.top-head {
  color: #fff;
  padding-top: 10px;
  font-size: 16px;
}

.margin-top-low {
  margin-top: 15px;
}

.margin-bottom-low {
  margin-bottom: 15px;
}

.slider-section {
  background: url(../imgs/PrimaryTexture-Alt.png);
}

.body-section {
  margin-bottom: 20px;
}

.body-section .container {
  padding-top: 20px;
}

.text-brown {
  color: #754809;
}

.text-red {
  color: #f61119;
}

.text-phone-big {
  font-size: 30px;
}

div.item-box {
  text-align: center;
  background: url(../imgs/PrimaryTexture-Alt.png);
  color: #F0A944;
  height: 310px;
  box-shadow: 5px 5px 5px #888888;
}

div.item-box h2 {
  margin: 0;
  font-size: 18px;
  color: #fff;
  background: url(../imgs/SecondaryTexture.png);
  border-bottom: solid 2px #2D373A;
  height: 40px;
  padding-top: 10px;

}

div.item-box h3 {
  margin: 0;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 10px;
}

div.item-box p {
  padding-top: 15px;
}

div.side-nav-container {
  background: url(../imgs/SecondaryTexture.png);
}

div.side-nav h2 {
  font-size: 20px;
  text-transform: none;
  color: #FFFFFF;
  margin: 15px 6% 0 -10%;
  padding: 7px 10%;
  position: relative;
  background: url(../imgs/PrimaryTexture.png);
}

div.side-nav h2:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
  border: 10px solid #121C0F;
  border-bottom-color: transparent;
  border-left-color: transparent;
  width: 0;
  height: 0;
}

div.side-nav ul {
  list-style: none;
}

div.side-nav ul li > a {
  color: #F3E9D8;
}

div.side-nav ul li a:before {
  font-size: 18px;
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  background-color: #2D373A;
  border: solid 1px #2D373A;
  position: relative;
  top: 13px;
  left: -6%;
}

div.side-zone {
  background: url(../imgs/SecondaryTexture.png);
  box-shadow: 5px 5px 5px #888888;
}

table.price-table {
  /*background: #fff;*/
  /*background: url(../imgs/SecondaryTexture.png);*/
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #75623F;
}

.table > tbody > tr > td {

  border-top: 1px solid #45584E;
}

.item-box a {
  color: #fff
}

a {
  color: #000;
}

div.item-box:hover {
  cursor: pointer;
  opacity: 0.9;
}

ul.services a, ul.services a:hover {
  cursor: default;
  text-decoration: none;
}

.social-links-footer img {
  padding-left: 10px;
  padding-top: 20px;
  width: 40px;
}

ul.text-big > li {
  margin: 0 0 3px 0;
}

li > a.first-title {
  color: #643E08;
  font-weight: bold;
  font-size: 19px;
}

li > a.first-title:hover {
  color: #2c3739;
  font-weight: bold;
}

/* gallery display */
.photos {
  display: block;
}

.photos ul {
  list-style: none;

}

.photos ul li {
  display: inline;
}

.photos ul li a {
  display: block;
  float: left;
  padding: 5px;
  opacity: 0.75;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.photos ul li a:hover {
  opacity: 1.0;
}

.photos ul li a img {
  border: 6px solid #765b38;
}

.bottom-inner-shadow {
  box-shadow: 0 5px 5px #888888;
}

/* CSS Clearfix */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* IE 6/7 */

/* lightbox hacks */
#lightbox-container-image-data-box {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/** uncomment to remove lightbox captions
 *
#lightbox-container-image-data-box {
  display: none !important;
}
 *
**/

.ng-invalid.ng-dirty, .has-error {
  border-color: #FA787E;
}

.ng-valid.ng-dirty {
  border-color: #78FA89;
}

.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}

.price-tag {
  font-size: 16px;
  font-weight: bold;
  color: #1f30d1;
}